import React, { Fragment } from "react";
import ResizedImage from "theme/components/atoms/ResizedImage";
import LabelledIcon from "theme/components/molecules/LabelledIcon";
import IsDesktop from "theme/utils/isDesktop";
import { FormattedMessage } from "react-intl";
import ekomiLogo from "./ekomi.webp";
import { withHideOnErrorBoundary } from "theme/components/helpers/ErrorBoundary";

const Ekomi = ({ loading, data }) => {
  if (loading || !data) {
    return false;
  }
  const { ekomiRating, lastEkomiFeedback } = data;
  if (!lastEkomiFeedback || !ekomiRating) {
    return false;
  }
  return (
    <section
      className="ekomi"
      itemScope
      itemType="https://schema.org/AggregateRating"
    >
      <ResizedImage src={ekomiLogo} alt="ekomi logo" cover format="thumbnail" />
      <IsDesktop>
        {(isDesktop) => {
          return isDesktop ? (
            <Fragment>
              <div className="ekomi__wrapper">
                <h3 className="ekomi__title">
                  <FormattedMessage
                    id="components.organisms.Ekomi.title"
                    defaultMessage="Customer feedbacks"
                  />
                </h3>
                <div className="ekomi__rating__star">
                  <div
                    className="c-rating c-rating--big"
                    data-rating-value={lastEkomiFeedback.dataRatingPercent}
                  >
                    <span>1</span>
                    <span>2</span>
                    <span>3</span>
                    <span>4</span>
                    <span>5</span>
                  </div>
                  <span className="ekomi__rating__rating">
                    <span
                      className="ekomi__rating__rating-value"
                      itemProp="ratingValue"
                    >
                      {lastEkomiFeedback.dataRatingPercent}
                    </span>
                    <span
                      className="ekomi__rating__best-rating"
                      itemProp="bestRating"
                    >
                      {" "}
                      / {lastEkomiFeedback.bestRating}
                    </span>
                  </span>
                </div>
              </div>
              <div className="ekomi__rating">
                <span className="ekomi__rating__count">
                  <FormattedMessage
                    id="components.organisms.Ekomi.totalFeedack"
                    defaultMessage="Total number of feedbacks:"
                  />
                  <span itemProp="ratingCount">
                    {" "}
                    {ekomiRating.ratingNumber}
                  </span>
                </span>
              </div>
              {lastEkomiFeedback && (
                <div className="ekomi__feedback">
                  <span className="ekomi__feedback__firstname">
                    {lastEkomiFeedback.firstname}
                  </span>
                  <span className="ekomi__feedback__rating">
                    {lastEkomiFeedback.rating}/5
                  </span>
                  <p className="ekomi__feedback__review">
                    {lastEkomiFeedback.review.substring(0, 100)}
                    {lastEkomiFeedback.review.length > 100 && "..."}
                  </p>
                </div>
              )}
            </Fragment>
          ) : (
            <Fragment>
              <h3 className="ekomi__title">
                <FormattedMessage
                  id="components.organisms.Ekomi.title"
                  defaultMessage="Customer feedbacks"
                />
              </h3>
              <div className="ekomi__rating">
                <div className="ekomi__rating__star">
                  <div
                    className="c-rating c-rating--big"
                    data-rating-value={lastEkomiFeedback.dataRatingPercent}
                  >
                    <span>1</span>
                    <span>2</span>
                    <span>3</span>
                    <span>4</span>
                    <span>5</span>
                  </div>
                  <span className="ekomi__rating__rating">
                    <span
                      className="ekomi__rating__rating-value"
                      itemProp="ratingValue"
                    >
                      {lastEkomiFeedback.ratingValue}
                    </span>
                    <span
                      className="ekomi__rating__best-rating"
                      itemProp="bestRating"
                    >
                      {" "}
                      / {lastEkomiFeedback.bestRating}
                    </span>
                  </span>
                </div>
                <span className="ekomi__rating__count">
                  <FormattedMessage
                    id="components.organisms.Ekomi.totalFeedack"
                    defaultMessage="Total number of feedbacks:"
                  />
                  <span itemProp="ratingCount">
                    {" "}
                    {ekomiRating.ratingNumber}
                  </span>
                </span>
                {lastEkomiFeedback && (
                  <div className="ekomi__feedback">
                    <span className="ekomi__feedback__firstname">
                      {lastEkomiFeedback.firstname}
                    </span>
                    <span className="ekomi__feedback__rating">
                      {lastEkomiFeedback.rating}/5
                    </span>
                    <p className="ekomi__feedback__review">
                      {lastEkomiFeedback.review.substring(0, 100)}
                      {lastEkomiFeedback.review.length > 100 && "..."}
                    </p>
                  </div>
                )}
              </div>
            </Fragment>
          );
        }}
      </IsDesktop>

      <a href="https://ekomi.fr/avis-clients-wwwcollegien-shopcom.html">
        <span className="ekomi__action button button--ghost button--default">
          <LabelledIcon icon="chevron-right" inverted size="tiny">
            <FormattedMessage
              id="components.organisms.Ekomi.buttonLabel"
              defaultMessage="Learn more"
            />
          </LabelledIcon>
        </span>
      </a>
    </section>
  );
};
export default withHideOnErrorBoundary(Ekomi);
